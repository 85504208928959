.hero {

	&-section {
		position: relative;
		background-repeat: no-repeat;
		background-size: cover;
		background-attachment: fixed;
		background-position: center;

		&:before {
			@media (min-width: $tablet720) {
				content: "";
				position: absolute;
				right: 0;
				z-index: 1;
				width: 100%;
				background: linear-gradient(1deg,#002733,rgba(0,39,51,0) 55.95%);
				transform: rotate(-180deg);
				height: 12%;
				top: -40px;
			}
		}

		&:after {
			content: "";
			position: absolute;
			right: 0;
			z-index: 1;
			width: 100%;
			background: linear-gradient(0deg, #002733 65%, rgba(0, 39, 51, 0) 89%);
			bottom: 0;
			height: 100%;
		}

		&--city {
			&:after {
				background: linear-gradient(0deg,#002733 45%,rgba(0,39,51,0) 100%);
			}
		}

		&--home {
			&:after {
				background: linear-gradient(0deg,#002733 30%,rgba(0,39,51,0) 100%);
				@media (min-width: $tablet720) {
					background: linear-gradient(0deg,#002733 50%,rgba(0,39,51,0) 100%);
				}
			}
		}

		&--test {
			&:after {
				background: linear-gradient(360deg, #002733 10.63%, rgba(0, 39, 51, 0.641892) 60.31%, rgba(0, 39, 51, 0) 80.99%) !important;
			}
		}
	}

	&-image {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}

	&-content {
		position: relative;
		z-index: 101;
		color: #fff;
		padding: 170px 25px 0;
		@media (min-width: $tablet720) {
			padding: 220px 25px 0;
		}
		@media (min-width: $medium) {
			max-width: 1395px;
			margin: 0 auto;
			padding: 400px 0 0;
		}

		&--city {
			padding: 200px 0 0;
			@media (min-width: $tablet720) {
				padding: 145px 0 0;
			}
			@media (min-width: $medium) {
				padding: 210px 0 0;
			}

			.hero-content__heading {
				@media (min-width: $tablet720) {
					display: flex;
					flex-direction: column;
					padding: 0 2% 1.3% 3%;
				}
				@media (min-width: $medium) {
					padding: 0 2% 4.3% 3%;
				}

				h1 {
					padding-left: 20px;
					@media (min-width: $tablet720) {
						order: 2;
						max-width: 85%;
						padding-left: 0;
					}
					@media (min-width: $medium) {
						max-width: 680px;
						order: 1;
					}
				}

				h2 {
					@media (min-width: $tablet720) {
						order: 3;
						max-width: 85%;
					}
					@media (min-width: $medium) {
						max-width: 850px;
						order: 2;
					}
				}

				.organiser-card {
					@media (min-width: $tablet720) {
						order: 4;
					}
				}

				.hero-content--cta {
					@media (min-width: $medium) {
						top: 9rem;
					}
				}
			}

			.hero-content__location {
				display: none;
				position: relative;
				flex-direction: column;
				font-size: 16px;
				line-height: 1;
				letter-spacing: -0.02em;
				font-family: 'Inter Bold', 'rawline';

				@media (min-width: $tablet720) {
					display: flex;
					order: 1;
					max-width: 300px;
					margin: 0 15px 17px auto;
				}
				@media (min-width: $medium) {
					order: 3;
					margin: 35px auto 0 35px;
				}
				&.hero-content__noaddress {
					&:before {
						top: -7px;
					}
				}

				&:before {
					content: '';
					position: absolute;
					left: -35px;
					top: 2px;
					width: 24px;
					height: 29px;
					background-size: 24px 29px;
					background-image: url("data:image/svg+xml,%3Csvg width='24' height='29' viewBox='0 0 24 29' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.62396e-05 12.0295C-0.00750113 8.94857 1.16406 5.86532 3.51472 3.51466C8.20101 -1.17163 15.799 -1.17163 20.4853 3.51466C22.8359 5.86532 24.0075 8.94857 24 12.0295H24.0003V12.0296C24.0003 17.0001 20.0003 21.0001 12.0003 29.0001L12.0001 28.9956L12 29.0001C4 21.0001 0 17.0001 0 12.0296V12.0295H3.62396e-05ZM12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19Z' fill='white'/%3E%3C/svg%3E%0A");
				}
			}

			.hero-content__notice {
				font-family: 'Inter', 'rawline';
				font-size: 14px;
				line-height: 21px;
			}
		}

		&--home {
			padding: 300px 0 0;

			@media (min-width: $tablet720) {
				padding: 260px 25px 0;
			}

			@media (min-width: $medium) {
				padding: 350px 0 0;
			}

			.hero-content__subtitle {
				@media (min-width: $medium) {
					margin-top: 20px;
				}
			}

			.hero-content__heading {
				padding: 0 15px 25px;

				@media (min-width: $tablet720) {
					padding: 0 25px 30px;
				}

				@media (min-width: $medium) {
					padding: 0 0 70px 3%;
					max-width: 1100px;
				}
			}

			&:after {
				background: linear-gradient(0deg,#002733 66%,rgba(0,39,51,0) 97.97%) !important;
				@media (min-width: $tablet720) {
					background: linear-gradient(0deg, #002733 14.09%, rgba(0, 39, 51, 0) 88.97%) !important;
				}
			}
		}

		&--about {
			padding: 350px 0 25px;
			@media (min-width: $tablet720) {
				padding: 350px 0 0;
			}
			@media (min-width: $medium) {
				padding: 400px 0 0;
			}
		}

		&__heading {
			z-index: 100;
			width: auto;
			padding: 0 25px;

			@media (min-width: $medium) {
				padding: 0 40px 40px;
			}
		}

		&__subtitle {
			font-family: 'Inter', 'rawline';
			font-weight: 400;
			font-size: 18px;
			letter-spacing: normal;
			@media (min-width: $tablet720) {
				font-size: 26px;
			}

			&-test {
				display: none;
				@media (min-width: $tablet720) {
					display: block;
				}
			}
		}

		&::after {
			content: "";
			position: absolute;
			right: 0;
			z-index: -1;
			width: 100%;
			background: linear-gradient(0deg, #002733 65%, rgba(0, 39, 51, 0) 88.97%);
			bottom: 0;
			height: 100%;
			@media (min-width: $tabletSmall) {
				content: none;
			}
		}
	}
}

body.city-exp-chal-1 .hero-content__subtitle {
	font-size: 16px;
	text-transform: uppercase;
	font-weight: 500;
	line-height: 1.1;
}