.price {

	&-section {
		position: relative;
		padding: 55px 0 25px;
		@media (min-width: $medium) {
			padding: 80px 0 40px;
		}

		.stripe {
			&--2 {
				position: absolute;
				display: none;
				@media (min-width: $tablet720) {
					display: block;
					bottom: -12rem;
					right: 3rem;
					width: 470px;
					transform: rotate(-118deg) scaleX(-1);
					z-index: 2;
				}
				@media (min-width: $medium) {
					bottom: 36.5rem;
					right: 10rem;
					left: 26rem;
					margin: 0 auto;
					width: auto;
					transform: unset;
				}
			}

			&--8 {
				position: absolute;
				display: none;
				@media (min-width: $medium) {
					display: block;
					bottom: 36.5rem;
					right: 8rem;
					left: 20rem;
					margin: 0 auto;
				}
			}
		}
	}
}

.packages {

	&-title {
		padding: 0 25px 0 50px;
	}

	&-container {
		z-index: 3;
		position: relative;
		@media (min-width: $medium) {
			max-width: 1395px;
			padding: 0 10px;
			margin: 0 auto;
		}
	}

}

.package {

	&-slider {
		padding: 20px 0 50px;
		@media (min-width: $tablet720) {
			padding: 15px 0 50px;
		}
		@media (min-width: $medium) {
			padding: 40px 0 50px;
		}

		.swiper-pagination {
			@media (min-width: $tabletBig) {
				display: none;
			}

			&-bullets {
				bottom: 15px!important;

				.swiper-pagination-bullet {
					margin: 0 15px;
					background: #fff;

					&-active {
						background-color: #fff;
						width: 11px;
						height: 11px;
						margin: -3px 15px!important;
					}
				}
			}

			&-bullet {
				opacity: 1;
				width: 5px;
				height: 5px;
			}
		}
	}

	&-slide {
		height: auto;
		box-shadow: 0 13px 24px -3px rgba(0, 0, 0, 0.2);
		border-radius: 15px;
		padding: 30px 30px 40px;
		flex-direction: column;
		justify-content: space-between;
		overflow: hidden;
		@media (min-width: $tablet720) {
			padding: 25px 10px 20px;
		}
		@media (min-width: $medium) {
			padding: 60px 30px 40px;
		}

		&--exp {
			padding: 0;
			.package-slide {
				&__head {
					img {
						height: 170px;
						object-fit: cover;
						@media (min-width: $medium) {
							height: 200px;
						}
					}
				}
				&__title {
					font-size: 24px;
					min-height: 55px;
					margin-bottom: 10px;
					font-weight: 400;
					font-family: $font-family-pp-gothic-medium;
					@media (min-width: $medium) {
						font-size: 24px;
						min-height: 53px;
					}
				}
				&__line {
					min-height: 60px;
					margin-bottom: 0;
					@media (min-width: $medium) {
						min-height: auto;
					}
				}
				&__price {
					font-size: 32px;
					font-weight: 500;
					min-height: 48px;
				}
				&__body {
					padding: 30px 15px 20px;
					align-items: start;
					@media (min-width: $tablet720) {
						padding: 25px 10px 20px;
					}
					@media (min-width: $medium) {
						padding: 60px 30px 30px;
					}
				}
				&__link {
					color: $deepBlue;
					border: 2px solid $deepBlue;
					border-radius: 50px;
					padding: 12px 30px;
					text-decoration: none;
					font-size: 14px;
					&.link-selected {
						background-color: $deepBlue;
						border-color: $deepBlue;
						color: #fff;
					}
					&:hover {
						background-color: $darkBlue;
						color: #fff;
					}
					&:focus {
						box-shadow: none;
					}
				}
				&__ul {
					font-size: 16px;
					list-style-type: none;
					li {
						position: relative;
						padding-bottom: 5px;
						font-size: 18px;
						&:before {
							content: '';
							position: absolute;
							top: 15%;
							left: -30px;
							width: 16px;
							height: 16px;
							background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_3525_1654)'%3E%3Cpath d='M1.92186 5.76003L7.04182 11.52' stroke='%2302CF6D' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.03906 11.5203L15.3402 3.20004' stroke='%2302CF6D' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_3525_1654'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
							background-repeat: no-repeat;
							background-size: contain;
						}
					}
				}
				&__bottom {
					flex: 1;
				}
			}
		}

		&__body {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		&__thumbnail {
			margin-bottom: 5px;
			width: 80px;
			height: 100px;
			@media (min-width: $medium) {
				width: 100px;
				margin-bottom: 35px;
			}
		}

		&__link, &__link h5 { // both required to work in Safari
			text-decoration: transparent underline;
			transition: text-decoration-color .3s;
			&:hover {
				text-decoration-color: $darkBlue;
			}
		}

		&__title {
			font-family: 'Inter Bold', 'rawline';
			font-size: 24px;
			line-height: 1;
			letter-spacing: -0.02em;
			margin-bottom: 30px;
			@media (min-width: $medium) {
				font-size: 32px;
				line-height: 1.1;
			}
		}

		&__line {
			font-size: 12px;
			line-height: 1.4;
			letter-spacing: -0.02em;
			@media (min-width: $tablet720) {
				font-size: 14px;
				line-height: 1.5;
			}
			@media (min-width: $medium) {
				font-size: 16px;
				margin-bottom: 25px;
			}
		}

		&__price {

			p {
				font-family: 'Inter Bold', 'rawline';
				font-size: 32px;
				line-height: 110%;
				text-align: center;
				letter-spacing: -0.02em;
				margin-bottom: 25px;
				@media (min-width: $medium) {
					margin-bottom: 30px;
				}
			}
		}

		&__button {

			button {
				font-family: 'Inter Bold', 'rawline';
				font-size: 12px;
				line-height: 1.3;
				text-transform: uppercase;
				border-radius: 63px;
				padding: 18px 29px;
				transition: all 0.3s;

				&:hover {
					background-color:$darkBlue!important;
					color: $lightYellow;
					text-decoration: none;
					box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
				}
				&:focus {
					box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .5);
				}
			}
		}

		&__btn {
			&:hover {
				color: #fff !important;
			}
		}
	}
}
