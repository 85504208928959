.top {
	&-cities {
		padding: 0 25px;
		@media (min-width: $tablet720) {
			padding: 0;
		}
		@media (min-width: $medium) {
			padding: 0 10px;
		}

		&__container {
			box-shadow: 0 18px 21px -4px rgba(0, 0, 0, 0.2);
			border-bottom-right-radius: 15px;
			border-bottom-left-radius: 15px;
			@media (min-width: $tablet720) {
				box-shadow: 0 18px 21px 11px rgba(0, 0, 0, 0.2);
			}
		}

		&__header {
			display: flex;
			flex-direction: column;
			border-top-right-radius: 15px;
			border-top-left-radius: 15px;
			overflow: hidden;
			@media (min-width: $tablet720) {
				flex-direction: row;
			}
		}

		&__footer {
			display: flex;
			padding: 55px 0 20px;
			@media (min-width: $tablet720) {
				padding: 25px 0 20px;
			}
			@media (min-width: $medium) {
				padding: 55px 0 65px;
			}
		}

		&__selector {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			@media (min-width: $tablet720) {
				width: 30%;
			}

			.transparent-city__select {
				width: 240px;
				background-color: #fff;

				.choices {

					&:before {
						background-image: url("data:image/svg+xml,%3Csvg width='17' height='22' viewBox='0 0 17 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.31634 21.6669C9.31634 21.6669 17 15.3634 17 8.55711C17 3.86269 13.1944 0.0571289 8.50002 0.0571289C3.80564 0.0571289 8.54922e-05 3.86269 0 8.55711C0 15.3634 7.68366 21.6669 7.68366 21.6669C8.13262 22.0352 8.86738 22.0352 9.31634 21.6669ZM4.22363 8.55697C4.22363 10.9149 6.14195 12.8333 8.5 12.8333C10.858 12.8333 12.7764 10.9148 12.7764 8.55688C12.7764 6.19892 10.858 4.28052 8.5 4.28052C6.14204 4.28052 4.22363 6.19901 4.22363 8.55697Z' fill='%23002733'/%3E%3C/svg%3E%0A");
					}

					&.cities-list--choices{

						&:after {
							background-image: url("data:image/svg+xml,%3Csvg width='11' height='7' viewBox='0 0 11 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.47066 5.23531L4.79264 5.91332L5.47066 6.59133L6.14867 5.91332L5.47066 5.23531ZM0.321988 1.44266L4.79264 5.91332L6.14867 4.55729L1.67801 0.0866361L0.321988 1.44266ZM6.14867 5.91332L10.6193 1.44266L9.2633 0.0866361L4.79264 4.55729L6.14867 5.91332Z' fill='%23002733'/%3E%3C/svg%3E");
						}
					}

					&__inner {
						color: #002733;
					}
				}
			}
		}

		&__test {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			@media (min-width: $tablet720) {
				width: 30%;
			}
		}

		&__text {
			width: 70%;
			display: none;
			@media (min-width: $tablet720) {
				display: block;
				padding: 0 20px;
			}

			p {
				max-width: 600px;
				margin-bottom: 0;
				@media (min-width: $tablet720) {
					font-size: 14px;
					line-height: 21px;
				}
			}
		}
	}
}
.city {

	&-card {
		width: 100%;
		height: 315px;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding-bottom: 45px;
		background-repeat: no-repeat;
		background-size: cover;
		@media (min-width: $tablet720) {
			width: 33.33%;
			height: 360px;
			padding-bottom: 0;
		}
		@media (min-width: $medium) {
			height: 580px;
			padding-bottom: 35px;
		}

		&:after {
			content:'';
			position: absolute;
			background: linear-gradient(0deg, #002733 9.94%, rgba(0, 39, 51, 0) 100.51%);
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}

		&__test {
			height: 255px;
			padding-bottom: 55px;
		}

		&__content {
			z-index: 1;
			position: relative;
			margin-bottom: 15px;
			@media (min-width: $tablet720) {
				margin-bottom: 30px;
			}
		}

		&__notice {
			font-size: 14px;
			line-height: 21px;
			letter-spacing: -0.02em;
			margin-bottom: 0;
		}

		&__location {
			line-height: 1;
			letter-spacing: -0.02em;
			margin:13px auto 5px;
			font-family: 'Inter Bold', 'rawline';
			@media (min-width: $medium) {
				font-size: 16px;
				margin:13px 0 5px;
			}
		}

		&__row {
			z-index: 1;
			position: relative;
			width: 240px;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			@media (min-width: $tablet720) {
				width: 100%;
				justify-content: space-evenly;
			}
			@media (min-width: $medium) {
				width: 240px;
				justify-content: space-between;
			}
		}

		&__link {
			font-size: 12px;
			line-height: 17px;
			font-family: 'Inter Bold', 'rawline';
			background-color: #fff;
			color: $deepBlue;
			border-radius: 63px;
			text-transform: uppercase;
			width: 91px;
			height: 48px;
			display: inline-block;
			padding: 13px 25px;
			align-items: center;
			border: 2px solid #fff;
			letter-spacing: .5px;

			&:hover {
				opacity: 0.8;
			}
		}

		.btn.btn-transparent {
			padding: 13px 25px;
			font-size: 12px;
			display: inline-block;
			@media (min-width: $tablet720) {
				padding: 13px 15px;
			}
			@media (min-width: $medium) {
				display: inline-block;
				padding: 13px 25px;
			}
		}
	}
}

