.service-block {
	padding: 30px 20px 20px;
	@media (min-width: $tablet720) {
		padding: 0 20px 45px;
		max-width: 1350px;
		margin: 0 auto;
	}


	&__text{
		font-size: 12px;
		line-height: 1.4;
		margin-top: 25px;
		@media (min-width: $tablet720) {
			font-size: 14px;
			line-height: 21px;
			margin-top: 25px;
		}
		@media (min-width: $medium) {
			font-size: 16px;
			line-height: 1.5;
			margin-top: 50px;
		}

		p,
		ul li{
			color:#fff;
			font-size: 14px;
			line-height: 21px;
			@media (min-width: $medium) {
				font-size: 16px;
				line-height: 1.5;
			}
		}
	}

	&__wrapper {
		max-width: 72%;
		margin: 0 0 0 auto;
		@media (min-width: $tablet720) {
			max-width: 380px;
			padding-right: 0;
		}
		@media (min-width: $medium) {
			max-width: 685px;
			margin: 0 auto;
			padding-left: 200px;
		}
		@media (min-width: $wide1440) {
			max-width: 1000px;
			padding-left: 360px;
		}
	}
}

.plan-section {
	position: relative;
	@media (min-width: $tablet720) {
		padding-top: 40px;
	}

	.dual-card {
		margin-top: 25px;
		@media (min-width: $tablet720) {
			flex-direction: row;
			min-height: 480px;
		}
		@media (min-width: $medium) {
			min-height: 610px;
		}

		&__map {
			@media (min-width: $tablet720) {
				width: 50%;
			}
			@media (min-width: $medium) {
				width: 67%;
			}

			.map-city-offices-wrapper {
				height: 360px;

				@media (min-width: $tablet720) {
					height: 100%;
				}
			}
		}

		&__block {
			@media (min-width: $medium) {
				padding: 60px 25px 65px 50px;
			}
		}
	}

	.service-block {
		@media (min-width: $tablet720) {
			padding: 0 20px 10px;
		}
		@media (min-width: $medium) {
			padding: 35px 20px 65px;
		}

		&__wrapper {
			max-width: unset;
			@media (min-width: $tablet720) {
				max-width: 71%;
				padding-left: 0;
				padding-right: 70px;
				margin: 0 0 0 auto;
				z-index: 3;
				position: relative;
			}

			h2 {
				max-width: 575px;
				overflow: hidden;
				text-overflow: ellipsis;
				line-height: 1.1;
			}
		}
	}

	.stripe {
		&--3 {
			position: absolute;
			bottom: 23rem;
			left: -18rem;
			width: 700px;
			transform: rotate(-113deg);
			z-index: 2;
			@media (min-width: $tablet720) {
				bottom: 13rem;
				left: -16rem;
				width: 800px;
				transform: rotate(-105deg);
			}
			@media (min-width: $medium) {
				bottom: 8rem;
				left: -61rem;
				right: 0;
				margin: 0 auto;
				width: 1250px;
				transform: rotate(-110deg);
			}
		}
		&--7 {
			position: absolute;
			z-index: 2;
			display: none;
			@media (min-width: $tablet720) {
				display: block;
				bottom: 13rem;
				left: 0;
				width: 500px;
			}
			@media (min-width: $medium) {
				bottom: 4rem;
				width: auto;
			}
		}
	}
}

.help {
	&-section {

		.stripe {
			&--6 {
				position: absolute;
				display: none;

				@media (min-width: $medium) {
					display: block;
					top: -10rem;
					right: 0;
				}
			}
		}
	}
}
