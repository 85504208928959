@import "./variables";
@import "./parts/hero-image-part";
@import "./parts/top-cities-part";
@import "./parts/dual-card-part";
@import "./parts/price-package-part";
@import "./parts/service-block-part";

.swiper {
	&-button {
		&-next-package,
		&-prev-package {
			width: 25px;
			height: 40px;
			background-size: 25px 40px;
			border-radius: unset;
			background-color: transparent;
			border-width: 0;
		}
	}
}

.help {

	&-section {
		position: relative;

		h2 {
			padding-right: 20px;
			@media (min-width: $tablet720) {
				padding-left: 40px;
				padding-right: 0;
			}
		}

		.swiper-pagination {
			@media (min-width: $tabletBig) {
				display: none;
			}

			&-bullets {
				bottom: 1px!important;

				.swiper-pagination-bullet {
					margin: 0 15px;
					background: #fff;

					&-active {
						background-color: #fff;
						width: 11px;
						height: 11px;
						margin: -3px 15px!important;
					}
				}
			}

			&-bullet {
				opacity: 1;
				width: 5px;
				height: 5px;
			}
		}

		&__container {
			margin-top: 25px;
		}

		&__wrapper {
			padding: 15px 0 30px;

			@media (min-width: $tablet720) {
				padding: 0;
			}
		}

		&__content {
			padding-top: 55px;
			padding-bottom: 30px;
			@media (min-width: $tablet720) {
				padding-top: 60px;
				padding-bottom: 20px;
			}
			@media (min-width: $medium) {
				padding-bottom: 75px;
			}
		}

		&__block {
			padding: 30px 10px 30px;
			flex-direction: column;
			background-color: transparent;
			box-shadow: 3px 11px 31px rgba(0, 0, 0, 0.2);
			border-radius: 15px;
			height: auto;
			justify-content: space-between;
			@media (min-width: $tablet720) {
				box-shadow: unset;
				padding: 55px 40px 40px;
			}

			.quiz-swipper_footer {
				display: none;
			}

			h3 {
				font-family: 'Inter Bold', 'rawline';
				line-height: 1.1;
				margin: 5px 0 20px;
				font-size: 18px;
				@media (min-width: $tablet720) {
					font-size: 24px;
				}
				@media (min-width: $medium) {
					font-size: 32px;
					margin: 40px 0 30px;
				}
			}

			a {
				width: 113px;
				height: 48px;
				margin: 0 auto;
				border-radius: 63px;
				display: flex;
				justify-content: center;
				align-items: center;
				text-transform: uppercase;
				color: #002733;
				font-family: 'Inter Bold', 'rawline';
				font-size: 12px;
				margin-top: 30px;
				@media (min-width: $medium) {
					margin-top: 5px;
				}

				&:hover {
					opacity: 0.8;
				}
			}

			&:first-child {

				a {
					background: #FFEEC1;

					&.help-section__button {
						border: 2px solid $darkYellow;
						color: $darkYellow;
						background-color: #fff;

						&:hover {
							background-color: $darkYellow;
							color: #fff;
						}
					}
				}
			}

			&:nth-child(2) {

				a {
					background: #C4D8FF;

					&.help-section__button {
						border: 2px solid $darkBlue;
						color: $darkBlue;
						background-color: #fff;

						&:hover {
							background-color: $darkBlue;
							color: #fff;
						}
					}
				}
			}

			&:last-child {

				a {
					background: #FFE4FB;

					&.help-section__button {
						border: 2px solid $pink;
						color: $pink;
						background-color: #fff;

						&:hover {
							background-color: $pink;
							color: #fff;
						}
					}
				}
			}

			img {
				width: 60px;
				@media (min-width: $medium) {
					width: 100%;
				}
			}
		}
	}
}

.lawyer {

	&-section {
		position: relative;
	}
}

.popular {

	&-section {

		&__content {
			max-width: 1440px;
			padding: 85px 0 0;

			.package {

				&-slider {
					padding: 5px 25px 50px;
					@media (min-width: $tablet720) {
						padding: 35px 25px 50px;
					}

					.swiper {
						&-pagination {
							display: block;
						}
					}
				}

				&-slide {
					box-shadow: 0 13px 24px 3px rgba(0, 0, 0, 0.2);

					&__button button:hover {
						box-shadow: none;
					}

					&:nth-child(3){
						.package-slide__link, .package-slide__link h5 {
							&:hover {
								text-decoration-color: $darkYellow!important;
							}
						}

						button {
							&:hover {
								background-color: $darkYellow!important;
							}
						}
					}

					&__line {
						margin-bottom: 25px;
						font-size: 14px;
						@media (min-width: $tablet720) {
							margin-bottom: 55px;
						}
					}

					.quiz-swipper_footer {
						display: none;
					}
				}
			}
		}

		&__heading {
			max-width: 1395px;
			margin: 0 auto;
			padding: 30px 20px 0 50px;
			@media (min-width: $tablet720) {
				padding: 0 20px 0 50px;
			}
		}
	}
}

.quote {

	&-container {
		position: relative;

		&--sign {
			padding: 10px 20px;
			min-height: 195px;
			margin-top: 30px;
			@media (min-width: $tablet720) {
				margin-top: 0;
				padding: 145px 0 310px;
			}
		}

		&--title {
			padding: 25px 25px 30px;
			@media (min-width: $tablet720) {
				padding: 25px 0 30px;
			}
			@media (min-width: $medium) {
				padding:25px 0 85px;
			}
		}


		&__bg1 {
			position: absolute;
			background-image: url("/wp-content/themes/fenix4/dist/images/hero/mob/285A5390-klar-mob.jpg");
			background-image: -webkit-image-set(url("/wp-content/themes/fenix4/dist/images/webp/hero/mob/285A5390-klar-mob.webp") 1x);
			background-repeat: no-repeat;
			background-position: center;
			opacity: 0.3;
			width: 100%;
			height: 100%;
			background-size: contain;
			top: 0;
			left: 0;
			@media (min-width: $tablet720) {
				background-size: cover;
				background-image: url("/wp-content/themes/fenix4/dist/images/hero/750x440/285A5390-klar-tablet.jpg");
				background-image: -webkit-image-set(url("/wp-content/themes/fenix4/dist/images/webp/hero/tablet/285A5390-klar-tablet.webp") 1x);
			}
			@media (min-width: 1367px) {
				background-image: url("/wp-content/themes/fenix4/dist/images/hero/1440x481/285A5390-klar-desktop.jpg");
				background-image: -webkit-image-set(url("/wp-content/themes/fenix4/dist/images/webp/hero/desktop/285A5390-klar-desktop.webp") 1x);
			}

			&:after {
				position: absolute;
				content: '';
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				background: radial-gradient(45.45% 53.45% at 43.48% 39.86%, rgba(0, 39, 51, 0.3) 0%, #002733 83.85%);
				@media (min-width: $tablet720) {
					background: radial-gradient(55.45% 95.45% at 58.48% 39.86%,rgba(0,39,51,.3) 0,#002733 70.85%);
					left: unset;
				}
			}
		}

		&__bg2 {
			position: absolute;
			background-image: url("/wp-content/themes/fenix4/dist/images/hero/mob/285A5390-klar-mob.jpg");
			background-image: -webkit-image-set(url("/wp-content/themes/fenix4/dist/images/webp/hero/mob/285A5390-klar-mob.webp") 1x);
			background-repeat: no-repeat;
			background-position: 75% 35%;
			opacity: 0.3;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			@media (min-width: $tablet720) {
				left: unset;
				background-image: url("/wp-content/themes/fenix4/dist/images/hero/750x440/285A5390-klar-tablet.jpg");
				background-image: -webkit-image-set(url("/wp-content/themes/fenix4/dist/images/webp/hero/tablet/285A5390-klar-tablet.webp") 1x);
			}
			@media (min-width: 1367px) {
				background-image: url("/wp-content/themes/fenix4/dist/images/hero/1440x481/285A5390-klar-desktop.jpg");
				background-image: -webkit-image-set(url("/wp-content/themes/fenix4/dist/images/webp/hero/desktop/285A5390-klar-desktop.webp") 1x);
			}

			&:after {
				position: absolute;
				content: '';
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				background: radial-gradient(45.45% 53.45% at 67.48% 55.86%,rgba(0,39,51,.3) 0,#002733 87.85%);
				@media (min-width: $tablet720) {
					left: unset;
				}
			}
		}
	}

	&-wrapper {
		position: relative;
		font-size: 16px;
		line-height: 1.5;
		z-index: 1;
		@media (min-width: $tablet720) {
			margin: 0 auto 0 50px;
			max-width: 475px;
		}
		@media (min-width: $medium) {
			margin: 0 130px 0 auto;
			max-width: 535px;
		}

		&--sign {
			text-align: right;
			right: 45px;
			left: 35px;
			top: 70px;
			position: absolute;
			max-width: 275px;
			margin: 0 auto 0 auto;
			@media (min-width: $tablet720) {
				max-width: 475px;
				margin: 0 0 0 auto;
				top: 185px;
				right: 50px;
			}
			@media (min-width: $medium) {
				max-width: 525px;
				margin: 0 0 0 auto;
				top: 210px;
				right: 95px;
			}

			p {
				color: $lightBlue;
				font-size: 14px;
				line-height: 1.4;
				letter-spacing: -0.02em;
				@media (min-width: $tablet720) {
					margin-bottom: 0;
				}
				@media (min-width: $medium) {
					font-size: 16px;
					line-height: 24px;
				}

				&:after,
				&:before {
					content:'';
					position: absolute;
					background-image: url("data:image/svg+xml,%3Csvg width='13' height='11' viewBox='0 0 13 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.83789 0.285156C2.02344 0.619141 0.441406 2.42969 0.441406 6.45508V10.3047H5.64453V5.59375H1.88281C1.93555 2.9043 3.14844 1.67383 6.03125 1.30469L5.83789 0.285156ZM12.377 0.285156C8.58008 0.619141 6.98047 2.42969 6.98047 6.45508V10.3047H12.2012V5.59375H8.43945C8.49219 2.9043 9.70508 1.67383 12.5879 1.30469L12.377 0.285156Z' fill='%23C4D8FF'/%3E%3C/svg%3E%0A");
					background-size: 13px 10px;
					background-repeat: no-repeat;
					width: 13px;
					height: 10px;
					@media (min-width: $tablet720) {
						display: block;
						width: 19px;
						height: 16px;
						background-size: 19px 16px;
					}
					@media (min-width: $medium) {
						width: 22px;
						height: 19px;
						background-size: 22px 19px;
					}
				}

				&:after {
					bottom: -5px;
					right: 0;
					@media (min-width: $tablet720) {
						bottom: -30px;
						right: 0;
					}
				}

				&:before {
					top: -15px;
					left: 0;
					@media (min-width: $tablet720) {
						top: -25px;
						left: 15px;
					}
					@media (min-width: $medium) {
						left: 5px;
					}
				}
			}
		}

		&--title {
			color: #FFE4FB;

			h2 {
				margin-bottom: 15px;
				@media (min-width: $medium) {
					margin-bottom: 40px;
				}
			}

			p {
				color: #FFE4FB;
				font-size: 14px;
				@media (min-width: $medium) {
					font-size: 16px;
				}
			}
		}
	}

	&-line {
		display: none;
		@media (min-width: $tablet720) {
			display: block;
			position: absolute;
			top: -100px;
			margin: 0 auto;
			right: 5px;
			z-index: 1;
			max-width: 1065px;
		}
		@media (min-width: $medium) {
			top: -165px;
			max-width: 1380px;
		}

		&-mob {
			position: absolute;
			top: 15px;
			max-width: 350px;
			margin: 0 auto;
			right: 15px;
			left:-10px;
			width: 100%;
			z-index: 1;
			@media (min-width: $tablet720) {
				display: none;
			}
		}

		&-title {

			display: none;
			@media (min-width: $tablet720) {
				position: absolute;
				display: block;
				width: 600px;
				bottom: 40rem;
				left: unset;
				right: -305px;
				margin: 0 auto;
			}
			@media (min-width: $medium) {
				max-width: unset;
				width: 835px;
				left: -450px;
				right: 0;
			}
		}
	}
}