.dual {

	&-card {
		padding: 0 25px;
		position: relative;
		z-index: 105;
		margin-top: 30px;
		@media (min-width: $tablet720) {
			display: flex;
			padding: 0 25px;
			max-width: 1350px;
			margin: 30px auto 0;
		}
		@media (min-width: $medium) {
			padding: 0 10px;
			max-width: 1395px;
			min-height: 600px;
		}

		&__subtitle {
			b {
				font-size: 18px;
				font-family: Inter Bold, sans-serif;
			}
		}

		&__right {
			border-bottom-left-radius: 15px;
			border-bottom-right-radius: 15px;
			overflow: hidden;
			@media (min-width: $tablet720) {
				border-top-right-radius: 15px;
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 15px;
			}
		}

		&__left {
			border-top-left-radius: 15px;
			border-top-right-radius: 15px;
			@media (min-width: $tablet720) {
				border-top-right-radius: 0;
				border-bottom-left-radius: 15px;
			}
		}

		&__about {
			margin-top: 20px;
			display: inline-block;
			color: $deepBlue;
			font-family: Inter Bold,rawline;
			text-transform: uppercase;
			border-radius: 50px;
			font-size: 12px;
			line-height: 16px;
			letter-spacing: -.02em;
			padding: 16px 30px;
			transition: all 0.3s;

			&:hover {
				color: #fff;
				text-decoration: none;
			}
		}

		&__link {
			padding: 16px 0;
			text-decoration: underline;

			&:hover {
				color: $darkBlue;
				text-decoration: underline!important;
				background-color: unset!important;
			}
		}

		&__block {
			color: #002733;
			padding: 25px 15px 25px 25px;
			@media (min-width: $tablet720) {
				width: 50%;
				padding: 35px 23px 40px;
			}
			@media (min-width: $medium) {
				width: 33%;
				padding: 60px 45px 65px 45px;
			}
		}

		&__half {
			@media (min-width: $tablet720) {
				width: 50%;
			}
		}

		&__video {
			border-bottom-left-radius: 15px;
			border-bottom-right-radius: 15px;
			overflow: hidden;
			@media (min-width: $tablet720) {
				border-top-right-radius: 15px;
				border-bottom-left-radius: 0;
			}

			a {
				display: block;
				height: 100%;
			}
		}

		&__image {
			@media (min-width: $tablet720) {
				width: 50%;
			}
			@media (min-width: $medium) {
				min-height: 600px;
				width: 67%;
			}

			img {
				width: 100%;
				object-fit: cover;
				object-position: center right;
				border-bottom-left-radius: 15px;
				border-bottom-right-radius: 15px;
				min-height: 285px;
				@media (min-width: $tablet720) {
					height: 100%;
					border-bottom-left-radius: 0;
					border-top-right-radius: 15px;
					object-position: 87% center;
				}
			}
		}

		&__map {
			width: 100%;
			background-color: #f2f3f7;

			.map-city-offices-wrapper {
				min-height: 225px;
				height: 225px;
				margin-bottom: 0 !important;
				@media (min-width: $tablet720) {
					height: 100%;
				}
			}
		}

		&__content {
			font-size: 12px;
			line-height: 17px;
			margin-top: 10px;
			@media (min-width: $tablet720) {
				font-size: 14px;
				line-height: 21px;
				margin-top: 25px;
			}
			@media (min-width: $medium) {
				font-size: 16px;
				line-height: 1.5;
				margin-top: 45px;
			}

			p {
				color: #002733;
				font-size: 12px;
				line-height: 1.4;
				letter-spacing: -0.02em;
				@media (min-width: $tablet720) {
					font-size: 14px;
					line-height: 1.5;
				}
				@media (min-width: $medium) {
					font-size: 16px;
				}
			}
		}

		&-city {
			height: auto;
			min-height: 50px;
			box-sizing: border-box;
			position: relative;
			margin-top: 20px;
			@media (min-width: $medium) {
				display: flex;
				align-items: center;
				margin-top: 0;
			}

			&__select {
				position: relative;
				width: 100%;
				max-width: 200px;
				margin: 30px auto 0 auto;
				height: 50px;
				background-color: transparent;
				border-radius: 63px;
				border: 2px solid #002733;
				box-sizing: border-box;
				@media (min-width: $tablet720) {
					margin: 50px auto 0 0;
				}

				#city-dropdown-header_cities_drop {
					opacity: 0;
				}

				.choices {
					position: relative;
					height: 50px;

					&__item {
						font-family: 'Inter', 'rawline';
						opacity: 1;
						font-size: 11px;
						line-height: 130%;
						text-transform: uppercase;
						color: #002733;
					}

					&__inner {
						padding-top: 0;
						padding-bottom: 0 !important;
						display: flex;
						align-items: center;
						height: 50px;
						border-width: 0;
						box-sizing: border-box;
						background-color: transparent;
						border-radius: 63px;
					}

					&__list--single {
						padding: 0 16px 0 4px;

						.choices__item {
							padding-left: 9px;
						}
					}

					&__list--dropdown {
						background-color: #edf0f6;
						top: 104%;

						.choices__item--selectable {
							padding-right: 10px;
						}

						&.is-active {
							z-index: 10;
							border-radius: 5px;
						}

						.choices__placeholder {
							display: none;
						}
					}
				}

				.choices {
					&.cities-list--choices {

						&:after {
							content: "";
							border-width: 0;
							background-image: url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.5L7 7.5L13 1.5' stroke='%23002733' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
							background-repeat: no-repeat;
							background-size: 14px 9px;
							width: 14px;
							height: 9px;
							right: 15px;
							top: 23px;
						}

						&.is-open {
							&:after {
								transform: rotate(180deg);
								top: 28px;
							}
						}
					}
				}
			}
		}

		&--city {
			margin-top: 25px;
			@media (min-width: $medium) {
				min-height: 560px;
			}

			img {
				min-height: 150px;
				width: 100%;
				height: auto;
				@media (min-width: $tablet720) {
					height: 100%;
				}
			}

			.dual-card__image {
				@media (min-width: $tablet720) {
					width: 36%;
				}
				@media (min-width: $medium) {
					min-height: 560px;
				}
			}

			.dual-card__block {
				@media (min-width: $tablet720) {
					width: 64%;
				}
			}
		}

		&--test{
			margin-top: 0;
			display: flex;
			flex-direction: column-reverse;
			@media (min-width: $tablet720) {
				flex-direction: row;
			}
		}

		&--lawyer {

			@media (min-width: $tablet720) {
				min-height: 480px;
				flex-direction: row;
			}
			@media (min-width: $medium) {
				min-height: 560px;
			}

			.dual-card__block {
				padding: 25px 15px 15px 15px;
				@media (min-width: $medium) {
					padding: 60px 85px 65px 45px;
				}
			}

			.dual-card__about {

				&:hover {
					background-color:$darkYellow;

				}
			}
		}

		&--test {
			.dual-card__right {
				border-top-left-radius: 15px;
				border-top-right-radius: 15px;
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
				@media (min-width: $tablet720) {
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 15px;
				}
			}

			.dual-card__block {
				border-bottom-left-radius: 15px;
				border-bottom-right-radius: 15px;
				border-top-left-radius: 0;
				border-top-right-radius: 0;
				overflow: hidden;
				@media (min-width: $tablet720) {
					border-top-left-radius: 15px;
					border-bottom-left-radius: 15px;
					border-bottom-right-radius: 0;
				}
			}
		}

		.lawyer-bg {
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
			width: 100%;
			border-radius: 3px;
			position: relative;
			height: 100%;

			&__image {
				width: 100%;
				height: 100%;
				min-height: 180px;
				display: flex;
				justify-content: center;
				align-items: center;
				background-size: cover;
				background-position: center;
			}
		}

		&--video {
			margin-top: 0;

			@media (min-width: $tablet720) {
				min-height: 480px;
			}
			@media (min-width: $medium) {
				min-height: 560px;
			}

			.dual-card__block {
				padding: 25px 15px 15px 15px;
				@media (min-width: $medium) {
					padding: 60px 85px 65px 45px;
				}

				.dual-card__about {

					&:hover {
						background-color:$darkBlue;

					}
				}
			}
		}

		.video-bg {
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
			width: 100%;
			border-radius: 3px;
			position: relative;
			height: 100%;

			&__image {
				width: 100%;
				height: 100%;
				min-height: 250px;
				display: flex;
				justify-content: center;
				align-items: center;
				background-size: cover;
				background-position: center;
			}

			&__icon {
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				left: 0;
				margin: auto;
			}
		}

		a {
			cursor: pointer;
		}
	}
}